import { Link } from 'react-router-dom'
import { faUserGroup, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Public = () => {
    const content = (
        <section className="public">
            <header>
                <h1>Welcome to <span className="nowrap">Home Management System!
                
                </span></h1>
                <FontAwesomeIcon icon={faUserGroup}/>
                <Link to="/login" style={{padding: "0.5em", textAlign:'end'}}>Member Login</Link>
            </header>
            <main className="public__main">
                <div className='center wrapper_frontPage'>
                    <div>
                        <div className='widget-title'>Better solutions for home management system</div>
                        <div className='widget-title widget-paragraph'>Transforming data in to Intellegent home.</div>
                        {/* <address className="public__addr widget-title">
                            Nathnael's Family<br />
                            4210 Esters Rd<br />
                            Irving, TX 75038<br />
                            <a href="tel:+15127609342">(512) 760-9342</a>
                        </address> */}
                            {/* <br/> */}
                        
                    </div>
                
                </div>
            </main>
            <footer>
                <FontAwesomeIcon icon={faUserLock}/>
                <Link to="/techAdmin" style={{padding: "0.5em"}}>Admin Login</Link>
            </footer>
        </section>

    )
    return content
}
export default Public
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'


const Welcome = () => {
    const { username, isManager, isAdmin } = useAuth()
    

    const date = new Date()
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date)

    const content = (
        <section className="center welcome">
                <h3>{today}</h3>

            <h3>Welcome {username}</h3>
            <section className='center_list welcome'>

                <p><Link to="/dash/notes">View techNotes</Link></p>

                <p><Link to="/dash/notes/new">Add New techNote</Link></p>
                
                {(isManager || isAdmin) && <p><Link to="/dash/users">View User Settings</Link></p>}

                {(isManager || isAdmin) && <p><Link to="/dash/users/new">Add New User</Link></p>}
            </section>
        </section>
    )

    return content
}
export default Welcome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetUsersQuery } from './usersApiSlice'
import { memo } from 'react'

const User = ({ userId }) => {

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
    })

    const navigate = useNavigate()
    
    // console.log(user.roles[0]=="TechAdmin");
    // vendors.some(e => e.Name === 'Magenic')
    // user.some(u=>u.roles==="TechAdmin"


    // console.log (Object.values(user).filter(u => u.roles !== "TechAdmin"))
    

    if (user && !(user.roles.includes("TechAdmin"))) {
    
        const handleEdit = () => navigate(`/dash/users/${userId}`)

        const userRolesString = (user.roles).toString().replaceAll(',', ', ')

        const cellStatus = user.active ? '' : 'table__cell--inactive'
        
        return (
            <tr className="table__row user">
                <td className={`table__cell ${cellStatus}`}>{user.username}</td>
                <td className={`table__cell ${cellStatus}`}>{userRolesString}</td>
                <td className={`table__cell ${cellStatus}`}>
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )

    } else return null
}

const memoizedUser = memo(User)

export default memoizedUser